import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"    transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
     

<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2555 1490 c122 -59 243 -180 300 -301 l17 -35 86 42 c87 43 206 74
280 74 114 0 208 -39 297 -122 204 -191 221 -522 39 -794 -93 -141 -451 -482
-489 -467 -12 4 -23 0 -31 -12 -8 -10 -14 -14 -14 -9 0 6 9 20 19 31 20 21 17
40 -3 24 -7 -6 -18 0 -30 17 -11 14 -13 22 -6 17 9 -5 11 -4 6 4 -4 6 -13 11
-19 10 -30 -4 -47 2 -47 16 0 8 -7 15 -15 15 -9 0 -20 11 -27 25 -6 13 -31 33
-55 45 -24 11 -42 24 -39 29 7 11 -23 37 -52 46 -28 8 -74 50 -65 58 3 4 1 7
-6 7 -16 0 -42 26 -34 34 4 3 1 6 -5 6 -12 0 -26 10 -82 61 -17 16 -34 29 -37
29 -3 0 -22 17 -42 38 -20 21 -51 45 -68 53 -20 8 -33 21 -33 32 0 10 -4 15
-10 12 -5 -3 -10 -1 -10 5 0 7 -7 10 -15 6 -8 -3 -14 0 -12 7 1 7 -4 11 -12 9
-7 -1 -18 1 -25 7 -6 5 -109 12 -228 16 -268 9 -254 16 -238 -122 6 -54 13
-116 16 -138 4 -30 3 -36 -5 -25 -6 8 -11 24 -12 35 -1 17 -2 17 -6 3 -2 -10
-13 -18 -24 -18 -12 0 -19 -7 -19 -19 0 -22 -49 -61 -75 -61 -9 0 -23 -7 -31
-15 -9 -8 -21 -15 -27 -14 -9 0 -8 2 1 6 13 5 17 23 4 23 -5 0 -27 -17 -50
-38 -34 -32 -39 -40 -30 -57 11 -20 11 -20 -10 -1 -17 15 -22 16 -22 5 0 -8 5
-20 11 -26 7 -7 9 -23 5 -39 l-7 -27 -23 24 c-212 229 -318 418 -361 641 -40
214 9 415 129 526 135 124 332 145 531 57 32 -14 63 -25 71 -25 7 0 29 28 50
62 72 118 200 220 310 247 22 6 75 8 119 5 67 -4 93 -11 160 -44z m-1777
-2067 c3 -21 0 -23 -47 -23 l-51 0 0 -145 0 -145 -35 0 -35 0 0 145 0 145 -44
0 c-47 0 -62 11 -52 36 5 12 28 14 133 12 124 -3 128 -4 131 -25z m234 -12
c26 -29 30 -39 25 -75 -4 -28 -15 -49 -33 -64 l-27 -24 31 -64 c18 -35 32 -67
32 -69 0 -3 -16 -5 -35 -5 -32 0 -36 4 -66 65 -26 52 -38 65 -56 65 -22 0 -23
-4 -23 -66 l0 -65 -32 3 -33 3 -3 155 c-1 85 0 160 3 168 4 11 25 13 96 10 89
-3 91 -4 121 -37z m126 -133 l2 -168 -35 0 -35 0 0 171 0 170 33 -3 32 -3 3
-167z m253 134 c51 -57 33 -136 -38 -163 -13 -5 -44 -9 -69 -9 l-44 0 0 -65 0
-65 -35 0 -35 0 0 163 c0 90 3 167 8 171 4 4 47 6 95 4 86 -3 90 -4 118 -36z
m119 -107 l0 -145 75 0 c73 0 75 -1 75 -25 l0 -25 -110 0 -110 0 0 170 0 170
35 0 35 0 0 -145z m400 120 c0 -25 -1 -25 -80 -25 l-80 0 0 -47 0 -48 70 3
c69 4 70 3 70 -22 0 -25 -1 -26 -70 -26 l-70 0 0 -50 0 -50 80 0 c79 0 80 0
80 -25 l0 -25 -115 0 -115 0 0 163 c0 90 3 167 7 170 3 4 55 7 115 7 l108 0 0
-25z m265 -148 l62 -168 -35 3 c-30 3 -37 8 -47 38 -12 35 -12 35 -78 38 l-66
3 -12 -41 c-11 -36 -15 -40 -46 -40 -18 0 -33 2 -33 4 0 1 27 77 60 167 33 90
60 165 60 167 0 2 16 2 36 0 l36 -3 63 -168z m145 28 l0 -145 75 0 c73 0 75
-1 75 -25 l0 -25 -110 0 -110 0 0 163 c0 90 3 167 7 170 3 4 19 7 35 7 l28 0
0 -145z m300 45 c28 -55 54 -100 58 -100 4 0 30 45 57 100 l49 100 38 0 38 0
0 -170 0 -170 -30 0 -29 0 -3 113 -3 112 -45 -87 c-39 -77 -48 -88 -71 -88
-23 0 -32 11 -70 85 l-44 85 -3 -110 -3 -110 -29 0 -30 0 0 170 0 170 35 0
c34 0 36 -2 85 -100z m453 89 c51 -23 63 -98 15 -84 -13 4 -29 15 -37 26 -16
23 -63 25 -81 4 -22 -26 -2 -53 58 -75 80 -30 102 -53 102 -107 0 -75 -65
-114 -155 -93 -43 10 -85 49 -85 81 0 29 62 27 75 -3 7 -17 20 -24 47 -26 32
-3 40 1 50 22 15 34 -7 59 -67 76 -64 18 -95 50 -95 98 0 61 35 91 107 92 23
0 53 -5 66 -11z m498 1 c21 -12 52 -66 45 -79 -9 -14 -60 1 -74 21 -20 28 -76
21 -80 -10 -5 -33 10 -46 76 -70 86 -32 112 -85 72 -149 -43 -72 -184 -64
-218 13 -18 38 -15 44 21 44 24 0 35 -6 42 -22 8 -18 18 -23 49 -23 26 0 42 6
50 17 17 27 -3 50 -63 74 -76 31 -101 56 -101 103 0 56 43 91 111 91 28 0 59
-5 70 -10z m149 -115 c0 -114 2 -127 21 -146 17 -17 29 -20 58 -15 55 9 61 24
61 163 l0 123 30 0 30 0 0 -136 0 -136 -33 -33 c-43 -43 -102 -54 -154 -28
-65 32 -73 52 -74 193 -1 69 -1 128 0 133 1 4 15 7 31 7 l30 0 0 -125z m435
110 c57 -23 70 -94 25 -132 -19 -16 -19 -17 5 -39 18 -17 25 -33 25 -61 0 -43
-5 -52 -40 -76 -19 -13 -46 -17 -117 -17 l-93 0 0 170 0 170 80 0 c48 0 95 -6
115 -15z m279 -7 c13 -13 27 -33 30 -44 10 -34 -39 -39 -68 -7 -30 32 -79 29
-84 -5 -5 -32 12 -48 68 -67 54 -18 100 -63 100 -98 0 -42 -36 -85 -80 -97
-40 -11 -58 -9 -113 12 -26 10 -57 52 -57 78 0 5 15 10 33 10 24 0 35 -6 42
-22 12 -29 75 -37 94 -14 26 31 5 62 -57 84 -78 29 -102 53 -102 102 0 30 6
43 31 64 42 35 126 37 163 4z m-1074 -178 l0 -30 -99 0 c-91 0 -100 2 -106 20
-11 35 3 40 106 40 l99 0 0 -30z m-1793 -342 c-2 -39 -5 -43 -29 -46 -27 -3
-28 -2 -28 42 0 44 1 46 30 46 29 0 30 -1 27 -42z m126 35 c-13 -2 -23 -11
-23 -19 0 -8 10 -15 23 -15 l22 -2 -22 -4 c-32 -6 -29 -31 5 -36 25 -4 25 -4
-5 -6 -33 -1 -33 0 -33 44 0 43 1 45 28 44 23 -2 24 -2 5 -6z m165 0 c-12 -3
-15 -13 -12 -39 2 -19 0 -34 -6 -34 -5 0 -10 15 -10 34 0 19 -6 37 -12 39 -7
3 3 5 22 5 19 0 27 -2 18 -5z m150 0 c-13 -3 -18 -16 -20 -46 l-1 -42 -4 40
c-3 22 -10 43 -16 47 -7 4 4 7 23 7 19 -1 27 -3 18 -6z m135 0 c-13 -2 -23
-11 -23 -19 0 -8 10 -15 23 -15 l22 -2 -22 -4 c-13 -2 -23 -11 -23 -18 0 -7
10 -16 23 -18 19 -4 18 -4 -5 -6 -27 -1 -28 1 -28 44 0 43 1 45 28 44 23 -2
24 -2 5 -6z m170 -6 c3 -8 1 -20 -4 -28 -5 -8 -6 -23 -4 -34 4 -17 3 -16 -6 3
-14 25 -29 29 -29 7 0 -8 -4 -15 -9 -15 -6 0 -9 18 -9 40 0 37 2 40 28 40 15
0 30 -6 33 -13z m240 6 c-27 -5 -31 -32 -5 -34 16 -1 16 -2 0 -6 -10 -2 -18
-11 -18 -19 0 -8 -4 -14 -10 -14 -5 0 -10 18 -10 40 0 39 1 40 33 39 23 -1 26
-3 10 -6z m188 -14 c24 -46 -33 -93 -69 -57 -13 13 -16 60 -5 71 15 16 63 6
74 -14z m147 -25 c-2 -37 -4 -42 -12 -25 -11 24 -26 28 -26 6 0 -8 -4 -15 -8
-15 -5 0 -8 18 -8 40 0 37 2 40 28 40 26 0 27 -2 26 -46z m248 28 c18 31 17
12 -1 -37 -8 -22 -14 -32 -15 -22 0 10 -7 32 -15 50 -15 31 -15 31 2 9 l16
-24 13 24z m190 -9 c9 -35 -7 -58 -42 -58 -22 0 -31 6 -38 27 -12 35 6 58 45
58 23 0 30 -6 35 -27z m110 -31 c8 -39 38 -23 45 23 l6 40 2 -43 c1 -31 -3
-44 -15 -49 -28 -11 -49 11 -51 53 -1 37 0 33 13 -24z"/>
<path d="M1505 2584 c7 -8 23 -26 34 -40 22 -28 38 -27 27 2 -3 9 -15 18 -26
21 -23 6 -26 19 -7 26 6 2 0 4 -15 4 -23 0 -25 -2 -13 -13z"/>
<path d="M3116 2413 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M867 1893 c-4 -3 -7 -28 -7 -55 l0 -48 36 0 c54 0 74 15 74 56 0 19
-6 36 -12 39 -32 9 -85 15 -91 8z"/>
<path d="M1240 1846 l0 -56 40 0 c27 0 47 7 60 20 40 40 16 80 -53 87 l-47 6
0 -57z"/>
<path d="M2056 1817 c-10 -28 -20 -58 -23 -64 -3 -9 8 -13 41 -13 25 0 46 3
46 8 0 7 -40 115 -44 119 -1 2 -10 -21 -20 -50z"/>
<path d="M4030 1855 l0 -45 43 0 c48 0 67 13 67 47 0 27 -27 43 -74 43 -36 0
-36 0 -36 -45z"/>
<path d="M4030 1710 l0 -50 41 0 c53 0 79 15 79 45 0 36 -26 55 -76 55 l-44 0
0 -50z"/>
<path d="M1520 1424 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z"/>
<path d="M1520 1385 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M2270 1425 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M2674 1426 c-10 -25 0 -51 20 -54 27 -6 48 25 35 49 -12 22 -47 25
-55 5z"/>
<path d="M2850 1425 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M3256 1425 c-9 -26 12 -57 37 -53 17 2 22 10 22 33 0 25 -4 31 -26
33 -17 2 -29 -3 -33 -13z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
